/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NikkeGuidesMiranda: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Miranda (Treasure) Guide & Review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_miranda.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Miranda (Treasure) Guide & Review</h1>
          <h2>
            A guide & review for Miranda (Treasure) in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>27/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Miranda (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="miranda-treasure"
                  enablePopover
                />
              </div>
              <h5>Can you give a brief summary about her changes?</h5>
              <p>
                Huge upgrade! Literally. Her changes open up a lot of new
                possibilities for her, from becoming more reliable as an SW
                buffer in PvE content to becoming an optional extra buffer in
                PvP for guaranteed critical attacks. We can't wait to talk about
                her!
              </p>
              <p>
                First of all, Miranda has finally become a universal buffer
                thanks to her ATK buff impacting not only one but two units of
                the highest ATK simultaneously. This is awesome because now she
                is not limited to 1 DPS comp only (like SW + Yulha) and benefits
                2 DPS comps. She also buffs herself, allowing to contribute some
                additional damage. And above all, she guarantees that the first
                shot from the ally with the highest ATK after entering Full
                Burst is a critical hit (with enough investments). These are all
                decent changes that we will unravel deeper in later parts of
                this guide.
              </p>
              <p>
                For now, we only need you to know that she's good. Not a
                must-have, but good.
              </p>
              <h5>Should I get her Favorite Item?</h5>
              <p>
                Only if you have a built SW. While her ATK buffs have become
                more universal, we find it challenging to slot her in the
                current meta teams due to existing formation limitations.
                Currently, most great CDRs are in the B1 position, and the meta
                is all about 1-1-2 comps with an off-burst DPS/support. Miranda
                as an off-burst support feels incomplete, and using her as an
                in-burst B1 means being forced to use B2 CDRs or waste a slot
                for CDR. Integrability is a serious problem for her.
              </p>
              <p>
                SW is an exception because her best team already uses a B2 CDR
                (and sometimes rarely a B1 too). Besides that, the benefit of
                guaranteed crit outweighs all other problems arising from CDR.
                It improves her overall damage and reduces fatigue from having
                to retry over and over again for critical hits. Definitely a
                life changer!
              </p>
              <p>
                PvP is also a fair argument for wanting her Treasure, but before
                you do that, you should know that she kinda only works best with
                one-shot Nikke(s), like SW and Maxwell but especially Emilia. As
                an OP unit, Emilia is used for her delayed AoE that counters
                Noah's (and even Biscuit's) immunity by one-shotting everyone.
                However, sometimes, one-shotting is not possible due to DEF
                buffs, damage reduction, Max HP▲, and lack of ATK. Currently, we
                solve this problem by using Red Hood B1, HelmTr Off-Burst, or
                other damage buffers that can help boost her damage. But, with
                Miranda, now you can take this one step further by ensuring her
                shot is a critical hit, dealing at least more than 100% more
                damage!
              </p>
              <p>
                Thus, whether you should get Miranda's Treasure depends on your
                alignment. If you are a PvE maniac, SW is an absolute bare
                minimum before pulling the trigger. If you are a PvP enthusiast,
                maybe having her will open up a lot of new possibilities for
                exploration. If you are already a champion, you promote Prydwen
                :D
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Miranda requires Phase 2 at least because that offers the
                biggest improvement to performance. Phase 3 is negligible, so
                you can always adjourn her metamorphosis and focus on another
                Treasure for the time being.
              </p>
              <h5>Skill 1</h5>
              <blockquote>
                <p>
                  ■ Activates after landing 30 normal attack(s). Affects all
                  allies.
                </p>
                <p>Hit Rate ▲ 5.44% for 5 sec.</p>
                <p>
                  ■ Activates after landing 30 normal attack(s). Affects all
                  allies with a Submachine Gun.
                </p>
                <p>Hit Rate ▲ 3.79% for 5 sec.</p>
                <p>
                  ■ Activates after landing 30 normal attack(s). Affects self.
                </p>
                <p>ATK ▲ 50.06% for 5 sec.</p>
              </blockquote>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Phase 3
                </h6>
                <p>
                  This skill unlocks after you have unlocked Miranda's Treasure
                  and collected 160 matching Favorite Item(s) material.
                </p>
              </blockquote>
              <p>
                Miranda gains a new self buff that increases her ATK temporarily
                by up to 50.06% for 5s every time she lands 30 normal attack(s).
                As usual, this buff is negligible because her personal output is
                weak, but damage is damage, and every million matters in Solo
                Raid. She has 250% Core Damage too. Therefore, you should still
                get Phase 3 but perhaps after you've completed your rodeo with
                every other Treasure. This will probably only matter if a Fire
                Weak Solo Raid arises and MirandaTr is relevant enough to be
                used.
              </p>
              <h5>Skill 2</h5>
              <blockquote>
                <p>■ Activates when entering Full Burst. Affects all allies.</p>
                <p>Critical Damage ▲ 32.99% for 10 sec.</p>
                <p>■ Activates when entering Full Burst. Affects self.</p>
                <p>Critical Rate ▲ 30.1% for 10 sec.</p>
                <p>Attack Damage ▲ 23.7% for 10 sec.</p>
                <p>
                  ■ Activates when entering Full Burst. Affects 1 ally unit(s)
                  with the highest ATK (except caster; including the caster if
                  there are not enough allies).
                </p>
                <p>Critical Rate ▲ 85.42% for 1 round(s).</p>
              </blockquote>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Phase 2
                </h6>
                <p>
                  This skill unlocks after you have unlocked Miranda's Treasure
                  and collected 50 matching Favorite Item(s) material.
                </p>
              </blockquote>
              <p>
                Miranda grants self additional CRIT Rate and ATK DMG upon
                entering Full Burst for 10s. Same reasoning as in Skill 1—it's
                mediocre but damage is damage. However, having 3 different
                offensive buffs helps with getting more output out of her due to
                their multiplicative nature.
              </p>
              <p>
                That being said, the third part of this skill is what makes her
                majestic. She can grant one ally with the highest ATK guaranteed
                crit (with enough investments) for one shot. This makes SW's,
                Maxwell's, and Emilia's Burst Skill, which already deals a lot
                of damage, more destructive. Just make sure whoever you want
                buffed has the Highest ATK!
              </p>
              <ul>
                <li>
                  In PvE, SW benefits best from this ability, and Miranda likely
                  will always accompany her. Having that QoL improvement of not
                  having to retry over and over again (malding) for critical
                  hits is a blessing for Raid tryhards.
                </li>
                <li>
                  In PvP, Emilia benefits best from this ability as her Burst
                  Skill can now almost guarantee (or guarantee) one-shots. In a
                  game mode where damage distance and core are nonexistent, CRT
                  becomes the most valuable asset. Her Critical DMG▲ and
                  Critical Rate▲ will ensure no one messes around. You can
                  combine this with ATK buffers like RH B1 or Maxwell for an
                  even greater effect (beware of who has the highest ATK).
                </li>
              </ul>
              <h5>Burst Skill</h5>
              <blockquote>
                <p>
                  ■ Affects 2 ally unit(s) with the highest ATK (except caster;
                  including the caster if there are not enough allies).
                </p>
                <p>ATK ▲ 40.4% for 10 sec.</p>
                <p>Critical Damage ▲ 56.23% for 10 sec.</p>
              </blockquote>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> Phase 1
                </h6>
                <p>
                  This skill unlocks after you have attached an appropriate SR
                  15 Doll + completed all the prerequisites.
                </p>
              </blockquote>
              <p>
                Miranda allows another DPS to deal more damage through buffing
                them with Burst Skill (it now affects 2 units), so with this,
                now you can:
              </p>
              <ul>
                <li>
                  Run regular 2 DPS teams like SBS + Alice, if you have no
                  better combination.
                </li>
                <li>
                  Run the goated classic Miranda + SW + Maxwell/other DPS team
                  with no issues.
                </li>
                <li>
                  Pair her with any other Nikke(s) released in the future that
                  benefit a lot from Critical Attacks.
                </li>
              </ul>
              <p>
                For veterans with a complete roster, there is no reason not to
                run her in SW + Maxwell comp when the situation calls for it
                because that's where she was born and where she will go back to.
                For beginners, you can always run Miranda + CDR + 2 B3 + FLEX
                for a decent temporary setup until new units come to your grasp.
              </p>
              <p>
                <i>
                  One good thing about Miranda's ATK buff is that it's not
                  caster's ATK, so dupes and personal stat are less important.
                </i>
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                Miranda is a great buffer for low-deficit gameplay. It allows
                your team to plow through weaker content and even one-shot the
                boss with ease with SW/Maxwell, or clear mobs with Emilia’s
                nuke. Not much to say here except that she is a good B1 Buffer.
                The only problem is that she is not a CDR unit, so you may want
                to pair her with B2 CDR like Dolla/S.Helm, or CDRs like DWife or
                Rouge, who’s burst is worse than Miranda’s.
              </p>
              <p>
                For high deficit, High ATK buff makes Miranda a decent
                alternative to the superior B1 units, if you do not have them.
                Depending on the team, Miranda can help ensure a 10-second buff
                instead of Liter’s 5s buff, which can help focus on targets such
                as the stage boss, totally avoiding confrontation when the boss
                appears and ending the battle as early as possible. She is not
                really meta, but in the broad sense there can be niche use for
                her.
              </p>
              <h5>
                Bossing - <strong className="a">A</strong>
              </h5>
              <p>
                Miranda will likely be used more for Bossing, especially Raids,
                where the situation favors SW. Not every boss is SW's punching
                bag, so you won't see her all the time, but when the right time
                comes, you might see her. For now, she has proven to S9 Ultra in
                Anomaly Interception alongside SW, although you can already do
                that without her. We also saw glimpses of her in the new Union
                Raid - Hard Mode, where she is often used alongside SW as a team
                2 for Iron Weak bosses.
              </p>
              <h5>
                PVP - <strong className="b">B</strong>
              </h5>
              <p>
                A niche buffer that can take Emilia's epic blast to the next
                level by ensuring critical and explosive damage. Complications
                may arise as to which ally has the highest ATK, requiring you to
                make compromise or unideal investments. That’s really all her
                use when it comes to PvP, an Emilia buffer.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                As Miranda's ATK buff doesn't scale off caster's stat, OL-ing
                her or even leveling her gear is unnecessary. However, she might
                benefit from increased personal damage if you do so with the
                correct lines. It is still unnecessary, though. Should you
                proceed (you believe in the ideology of "damage is damage"),
                then go for ELE & ATK. They're handy for Fire-Weak Raids (but
                only when SW is appropriate).
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> -
                </li>
                <li>
                  <strong>Passable:</strong> 4× ELE, 4× ATK
                </li>
                <li>
                  <strong>Priority:</strong> Low
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                Depending on whether you believe "damage is damage" and whether
                your wallet is thick, investment advice varies. In general,
                focus on S2 until critical hit is guaranteed, then Burst Skill.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 4~7</strong>
                </li>
                <ul>
                  <li>
                    Hit Rate is trivial, and personal damage is only okay if you
                    are building her to the very max. Upgrade to 4 for CP
                    padding; it's enough for most people.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4~10</strong>
                </li>
                <ul>
                  <li>
                    Discretionary. 4 is a good start but only upgrade higher
                    till critical hits from SW, Maxwell, Emilia, or units alike
                    are guaranteed. This requires 100% - 15% BASE CRT RATE = 85%
                    CRT RATE from external sources. This can come from the
                    unit's own skillset (like SW), OL lines, support skills,
                    etc. You don't really need 100%, but the more, the better,
                    because less chance of "having to retry again because one of
                    your shots don't crit".
                  </li>
                  <li>
                    At level 10, this skill always guarantees crit hit unless a
                    crit debuff comes in the future.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:5~10</strong>
                </li>
                <ul>
                  <li>
                    Upgrade for more damage. ATK buffs are very valuable. 10 is
                    the end goal here, but manage your resources well.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="miranda-treasure" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_3_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_13_sm.webp"
                alt="Guide"
              />
              <p>
                For PvE content, use the cube with the highest level to increase
                her personal damage but limit yourself to either of these two
                for higher uptime. The new Destruction cube is also fine if the
                boss's part is tanky.
              </p>
              <StaticImage
                src="../../../images/nikke/generic/cube_9_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_11_sm.webp"
                alt="Guide"
              />
              <p>
                In PvP, the most ideal cubes are always the defensive ones, but
                if you don't have, the other cubes like Resilience or Bastion
                are also fine.
              </p>
              <h5>Doll Investments</h5>
              <p>
                You need SR 15 to Treasure her. No additional information
                needed.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The SW Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miranda-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="placeholder-box">B2</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="snow-white"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="mari-makinami-illustrious"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="yulha" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This is Miranda’s statement team. In this team, we focus on
                buffing SW's and Maxwell's personal damage through Burst Skill
                and guaranteeing SW's Burst Shot 24/7. You can recruit Crown,
                Grave, or any other relevant units to play the B2 position. You
                may use HelmT or Helm over Maxwell if the team requires healing
                to survive. Summer Helm or Mari are also options if you want to
                funnel all the damage in SW’s favour.
              </p>
              <h5>Team #2: Fire Away</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miranda-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Kisenix likes the Fire Team, so here is a Fire Team to showcase
                her "buffing 2 random DPS" capability. In this comp, we give
                Asuka and Rapipi as much ATK and CRT DMG as possible. Asuka can
                also buff Miranda's core damage. Rei can also be subbed in to
                give Miranda more ATK, as an in-burst B3 or as an off-burst
                acting like Maxwell.
              </p>
              <h5>Team #3: Fire Team Again with Off-Burst CDR</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miranda-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami"
                      enablePopover
                    />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                In this comp, we use a more impactful B2's Burst Skill and
                instead relegate our CDR into the off-burst position.
              </p>
              <h5>Team #4: General Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miranda-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This is the general team build for Miranda teams. The flex spot
                needs to be a B2 unit if D:Wife is being used as the CDR for the
                team.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: TOS = Time to One Shot</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miranda-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                In this comp, we play it crazy by using as many buffers as
                possible (completely unnecessary; for demonstration purposes
                only) and pair Emilia together with Red Hood B1, HelmTr
                Off-Burst, and Miranda Off-Burst. This grants her insane ATK
                buff, ATK DMG, and CRT DMG + guaranteed crit that will surely
                demolish any un-immuned/indomed enemies. For a more realistic
                example, you can use Noah Biscuit Emilia RedHood Miranda instead
                for increased survivability, saving HelmTr for other teams.
              </p>
              <h5>Team #2: Poor TOS</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="miranda-treasure"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is 3 RL, sure it’s slower but you can save Red Hood
                for other teams compared to the traditional Emilia-RH 3 RL ICBM.
                You will need Quantum Cube lv 7 on Emilia and either
                Biscuit/Rapunzel. With the 100% crit shot + Blanc debuff, we’re
                pretty sure they either go Noah here or just die. If your Emilia
                also has 40% ATK, can replace Blanc with Bay.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Guaranteed crit for one shot after Burst; great
                          synergy with SW, MW & Emilia and opens up a lot of
                          potential.
                        </li>
                        <li>Strong niche.</li>
                        <li>
                          ATK buff now applies to 2 units, making her more
                          universal.
                        </li>
                        <li>Personal damage increased.</li>
                        <li>
                          Quite cheaper to build skill-wise compared to recent
                          releases because S1 can be ignored, and you only need
                          S2 + Burst Skill.
                        </li>
                        <li>Usable at Phase 2.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Restricted to SW/Emilia for the best potential.</li>
                        <li>
                          Costly to build overall as it requires SR 15 Doll.
                        </li>
                        <li>
                          Guaranteed crit can target the wrong ally if the ally
                          you want to be targeted has an ATK lower than someone
                          else.
                        </li>
                        <li>
                          While influential, quite niche in PvE and PvP as her
                          presence is not needed all the time.
                        </li>
                        <li>
                          HelmTr's and DrakeTr's upgrades overshadow hers.
                        </li>
                        <li>
                          Still no CDR. B1 without CDR usually has integration
                          issues.
                        </li>
                        <li>Phase 3 does not help DPS's damage.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMiranda;

export const Head: React.FC = () => (
  <Seo
    title="Miranda (Treasure) Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Miranda (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
